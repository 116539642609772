<template>
  <page-view>
    <div class="record_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0, marginRight: '56px' }">
          <div class="filter_item">
            <span>支付宝登录名</span>
            <a-input placeholder="支付宝登录名" class="filter_input" v-model.trim="queryParam.alipayAccount" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0, marginRight: '44px' }">
          <div class="filter_item">
            <span>支付宝姓名</span>
            <a-input placeholder="支付宝姓名" class="filter_input" v-model.trim="queryParam.alipayName" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0, marginRight: '29px' }">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker @change="onChange" class="filter_input" :value="[moment(queryParam.startCreateTime), moment(queryParam.endCreateTime)]" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <a-button type="primary" class="query_btn" @click="$refs.table.refresh(false)">查询</a-button>
            <a-button @click="resetQueryParam">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="record_table_wrapper">
      <!-- <a-tabs :activeKey="activeTabKey" @change="handleTabChange">
        <a-tab-pane tab="全部" key="all"></a-tab-pane>
        <a-tab-pane tab="审核通过" key="WITHDRAW"></a-tab-pane>
        <a-tab-pane tab="人工转账" key="MANUAL"></a-tab-pane>
        <a-tab-pane tab="拒绝审核" key="FAILURE"></a-tab-pane>
      </a-tabs> -->
      <s-table style="margin:0 24px" ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @click="copyInfo(record.uid)" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)">
              {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.show">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="username" slot-scope="text">
          <span v-if="text">{{ text }}</span>
          <span v-else>-</span>
        </template>
        <template slot="alipayAccount" slot-scope="text">
          <span v-if="text">{{ text }}</span>
          <span v-else>-</span>
        </template>
        <template slot="alipayName" slot-scope="text">
          <span v-if="text">{{ text }}</span>
          <span v-else>-</span>
        </template>
        <template slot="balanceCash" slot-scope="text">
          <span style="color:#F93244">{{ text }}</span>
        </template>
      </s-table>
    </div>
  </page-view>
</template>

<script>
import { PageView } from '@/layouts'
import { STable } from '@/components'
import { billAdminList } from '@api'
import { formatDate } from '@/utils/util'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default {
  components: {
    PageView,
    STable
  },
  data () {
    return {
      // activeTabKey: 'all',
      queryParam: {
        startCreateTime: new Date(new Date().toLocaleDateString()).getTime(),
        endCreateTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000,
        types: 'BALANCE_REFUND,BALANCE_REFUND_FAILURE'
      },
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '用户名',
          dataIndex: 'username',
          scopedSlots: { customRender: 'username' }
        },
        {
          title: '支付宝登录名',
          dataIndex: 'alipayAccount',
          scopedSlots: { customRender: 'alipayAccount' }
        },
        {
          title: '支付宝真实姓名',
          dataIndex: 'alipayName',
          scopedSlots: { customRender: 'alipayName' }
        },
        {
          title: '提现金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' }
        },
        {
          title: '备注',
          dataIndex: 'comment',
        },
        {
          title: '审核时间',
          dataIndex: 'createTime',
          customRender: formatDate
        }
      ],
      dataSource: (parameter) => {
        const temp = {...parameter, types: 'BALANCE_REFUND,BALANCE_REFUND_FAILURE'}
        return billAdminList(Object.assign(temp, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => (item.show = false))
          return result.data
        })
      }
    }
  },
  methods: {
    moment,
    resetQueryParam () {
      // this.activeTabKey = 'all'
      const today = new Date(new Date().toLocaleDateString()).getTime()
      // 查今天的全部
      this.queryParam = {
        startCreateTime: today,
        endCreateTime: today + 24 * 60 * 60 * 1000
      }
      this.$refs.table.refresh(false)
    },
    handleMouseover (record) {
      record.show = true
    },
    handleMouseout (record) {
      record.show = false
    },
    // handleTabChange (key) {
    //   this.activeTabKey = key
    //   if (key === 'all') {
    //     this.queryParam.types = 'WITHDRAW,FAILURE,MANUAL'
    //     this.queryParam.type && delete this.queryParam.type
    //   } else {
    //     this.queryParam.type = key
    //     this.queryParam.types && delete this.queryParam.types
    //   }
    //   console.log(this.queryParam)
    //   this.$refs.table.refresh(false)
    // },
    onChange (date, dateString) {
      const time = dateString.includes('')
      if (time) {
        delete this.queryParam.startCreateTime
        delete this.queryParam.endCreateTime
      } else {
        this.queryParam.startCreateTime = Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8
        this.queryParam.endCreateTime = Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8
      }
      this.currentShortcutDate = null
      this.$refs.table.refresh(false)
    }
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0 !important;
}
.record_header_wrapper {
  padding: 24px 40px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}

.record_table_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  padding-bottom: 24px;
  border-radius: 4px;
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
</style>
